body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	user-select: none;
	-webkit-user-select: none;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

body {
	padding-top: constant(safe-area-inset-top); //for iOS 11.2
	padding-top: env(safe-area-inset-top); //for iOS 11.1
}

body,
#root,
html {
	font-family: "Charlie Display" !important;
	height: 100%;
	background-color: #eae9c1;
}

// /* SCREEN LOCK */
// @media screen and (max-width: 1000px) {
//   html {
//     transform: rotate(-90deg);
//     transform-origin: left top;
//     width: 100vh;
//     overflow-x: hidden;
//     position: absolute;
//     top: 100%;
//     left: 0;
//   }
// }

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

main::-webkit-scrollbar {
	width: 0px;
	background: transparent;
}

/* REACT DATES CALENDAR FIX */
.DateInput_input__small {
	font-size: 14px !important;
	font-weight: 600 !important;
}

.SingleDatePicker_picker {
	z-index: 10;
}

.DateInput__small {
	width: 100% !important;
}

.DateInput__small>input {
	text-align: center;
	height: 30px !important;
	border-radius: 5px !important;
}

.DateInput_input__focused {
	border-bottom: 2px solid #e36a53 !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
	background: #e36a53 !important;
	border: 1px double #e36a53 !important;
	color: #fff;
	font-weight: bold !important;
}

.SingleDatePickerInput__withBorder {
	border-radius: 5px !important;
	border: 1px solid #dbdbdb;
}

.DateInput {
	border-radius: 5px !important;
}

/* JSON DEBUG FIX */
.__json-pretty__ {
	padding: 7px;
	border-radius: 5px;
}

/* VIRTUAL TABLE FIX */
.v-l {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 5px 10px;
	border-right: 1px solid #f0f0f0;
	border-bottom: 1px solid #f0f0f0;
	// display: flex;
	// align-items: center;
	// justify-content: center;
}

.d-none {
	display: none;
}

.rce-mbox {
	padding: 6px 9px 22px 9px;
}

.rce-mbox-time {
	bottom: -9px !important;
}

.rce-container-citem {
	margin-bottom: 15px;
}

.rce-mbox.rce-mbox-right {
	overflow: hidden;
}

.rce-mbox-photo--img {
	cursor: pointer;
}

.rce-citem-avatar img {
	width: auto !important;
	height: auto !important;
}

.rce-container-citem {
	width: 350px;
}

.rce-citem {
	cursor: default !important;
}

.fc-event {
	margin-bottom: 3px;
	padding-left: 5px;
}

.scheduler_default_corner {
	div {
		display: none;
	}
}

.scheduler_default_timeheadercol_inner {
	border: none !important;
}

.scheduler_default_main {
	border: none !important;
}

.scheduler_default_timeheadergroup {
	font-family: Charlie Display;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	color: #894386 !important;
	background: #f8f8f9 !important;
}

.scheduler_default_corner {
	background: #f8f8f9 !important;
}

.scheduler_default_timeheadercol {
	font-family: Charlie Display;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
	text-align: center;
	color: #59596a !important;
	background: #f8f8f9 !important;
}

.scheduler_default_divider_horizontal {
	background-color: transparent !important;
}

.scheduler_default_rowheader {
	font-family: Charlie Display;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 14px;
	color: #59596a;
	background-color: #ffffff !important;
}

.scheduler_default_cell.scheduler_default_cell_business {
	background-color: #ffffff !important;
}

.scheduler_default_cell {
	background-color: #f9f9fe !important;
}

.scheduler_default_rowheader_inner {
	position: absolute;
	border-right: 1px solid #f9f9fe !important;
	padding: 7px;
	display: flex;
	align-items: center;
}

.scheduler_default_timeheadergroup_inner {
	border-right: none !important;
	border-bottom: 1px solid #d7e1f0b0 !important;
}

.scheduler_default_resourcedivider {
	background: #d7e1f0b0 !important;
}

.scheduler_default_divider,
.scheduler_default_splitter {
	background: #d7e1f0b0 !important;
}

.scheduler_default_matrix_horizontal_line {
	background: #d7e1f0b0 !important;
}

.scheduler_default_scrollable {
	&::-webkit-scrollbar {
		width: 2px;
	}

	&::-webkit-scrollbar-track {
		border-radius: 5px;
		background: #f1f1f1;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 5px;
		background: #bebebe;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

.scheduler_default_event {
	font-family: Charlie Display;
	font-style: normal;
	font-weight: 900;
	font-size: 14px !important;
	line-height: 16px;
	color: #465ac2 !important;
}

.scheduler_default_event_inner {
	position: absolute;
	padding: 12px !important;
	border: none !important;
}

.scheduler_default_event_bar {
	display: none;
}

.scheduler_default_event_inner {
	background: #e3eeff !important;
	border-radius: 8px !important;
	margin: 3px;
}

.rce-container-citem {
	margin: 5px 10px;
}

ul {
	list-style-type: none !important;
}

#formRow {
	margin-bottom: 0 !important;
}

.ant-descriptions .ant-descriptions-item-content {
	background: white;
	border-radius: 5px;
	padding: 2px 5px;
}

.ant-descriptions-item-label {
	background-color: rgba(236, 236, 236, 0) !important;
	width: 70px;
}

.ant-modal-content {
	background: #c5d4bb !important;
}

.ant-modal .ant-modal-title {
	background: #c5d4bb;
}

.ant-table-thead .ant-table-cell {
	background: #515151 !important;
	color: white !important;
}

.even-row {
	background-color: white !important;
	color: #000;
}

.odd-row {
	background-color: rgb(224, 243, 221);
	color: #000;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper {
	width: 100% !important;
	display: flex !important;
	justify-content: center !important;
	padding: 10px !important;
}

:where(.css-dev-only-do-not-override-1kxnq9v).ant-input-affix-wrapper .anticon.ant-input-password-icon {
	color: rgba(0, 0, 0, 0.45);
}

:where(.css-dev-only-do-not-override-lunazh).ant-tooltip .ant-tooltip-inner {
	color: #222;
}

.ant-ribbon {
	inset-inline-end: 3px !important;
}

.ant-ribbon {
	top: 2px !important;
}

.gantt .handle {
	display: none !important;
}

.details-container {
	display: flex;
	flex-direction: column;
	width: 180px;
	background-color: white;
	color: black;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
	padding: 10px;

	&>h1 {
		margin: 0px;
		border-bottom: 3px solid #a3a3ff;
		margin-bottom: 6px;
	}
}

.ganttProcess {
	&>g>.bar {
		fill: #1677ff !important;
	}

	&>g>.bar-label {
		fill: black !important;
		font-weight: 500;
	}

	&>g>.bar-progress {
		fill: #52c41a !important;
	}
}

.ganttFinished {
	&>g>.bar {
		fill: #52c41a !important;
	}

	&>g>.bar-label {
		fill: black !important;
		font-weight: 500;
	}

	&>g>.bar-progress {
		fill: #52c41a !important;
	}
}

.ganttPending {
	&>g>.bar {
		fill: #faad14 !important;
	}

	&>g>.bar-label {
		fill: black !important;
		font-weight: 500;
	}

	&>g>.bar-progress {
		fill: #52c41a !important;
	}
}

.ganttArchived {
	&>g>.bar {
		fill: rgb(197, 197, 197) !important;
	}

	&>g>.bar-label {
		fill: black !important;
		font-weight: 500;
	}

	&>g>.bar-progress {
		fill: #52c41a !important;
	}
}

.ganttBlock {
	&>g>.bar {
		fill: rgb(255, 80, 80) !important;
	}

	&>g>.bar-label {
		fill: black !important;
		font-weight: 500;
	}

	&>g>.bar-progress {
		fill: #52c41a !important;
	}
}

.ant-picker-dropdown {
	@media (max-width: 768px) {
		inset: 51.9333px auto auto 0px !important;
	}
}

.ant-picker-dropdown .ant-picker-panel-container {
	@media (max-width: 768px) {
		max-width: 100vw !important;
		overflow: auto !important;
		inset: 51.9333px auto auto 0px !important;
	}
}

/* Estilos para las opciones seleccionadas en el selector de mes/año */
.ant-select-item-option-selected {
	.ant-select-item-option-content {
		color: white !important;
	}
}

/* Cambiar el fondo del día actual */
.rbc-today {
	background-color: rgba(0, 123, 255, 0.1) !important;
	/* Un azul transparente */
}

.rbc-month-row {
	border: 1px solid #ddd;
	border-color: black !important;
	border-top: 0px solid #ddd !important;
}

.rbc-month-header {
	border: 1px solid black;
}

.rbc-header+.rbc-header {
	border-left: 1px solid black !important;
}

/* Cambiar el color de las líneas de separación de días */
.rbc-day-bg {
	border-color: black !important;
}

@keyframes border-flicker {
	0% {
		border-color: yellow;
	}

	50% {
		border-color: black;
	}

	100% {
		border-color: yellow;
	}
}

.notification-event {
	background-color: #95c1eb;
	border: 5px solid;
	/* Define un borde para la animación */
	animation: border-flicker 500ms infinite;
}

/* Estilos para react-big-calendar */
.rbc-calendar {
	background-color: white;
	padding: 20px;
	border-radius: 12px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Cabecera del mes */
.rbc-toolbar {
	margin-bottom: 20px;
	padding: 10px;

	.rbc-toolbar-label {
		font-size: 1.5em;
		font-weight: 600;
		color: #333;
	}

	.rbc-btn-group {
		button {
			background: white;
			color: #666;
			border: 1px solid #ddd;
			padding: 8px 12px;
			border-radius: 6px;
			margin: 0 4px;
			font-weight: 500;
			transition: all 0.2s ease;

			&:hover {
				background: #f0f0f0;
				border-color: #bbb;
			}

			&.rbc-active {
				background: #1a73e8;
				color: white;
				border-color: #1a73e8;
			}
		}
	}
}

/* Cabecera de los días */
.rbc-header {
	padding: 12px 3px;
	font-weight: 600;
	font-size: 0.9em;
	color: #444;
	background: #f8f9fa;
	border: none !important;
	border-bottom: 1px solid #e0e0e0 !important;
}

/* Celdas de días */
.rbc-month-view {
	border: 1px solid #e0e0e0;
	border-radius: 8px;
	overflow: hidden;

	.rbc-month-row {
		border-color: #e0e0e0 !important;
	}

	.rbc-day-bg {
		border-color: #e0e0e0 !important;

		&.rbc-today {
			background-color: rgba(25, 118, 210, 0.05) !important;
		}

		&:hover {
			background-color: rgba(0, 0, 0, 0.02);
		}
	}
}

/* Eventos */
.rbc-event {
	border-radius: 4px;
	padding: 2px 5px;
	font-size: 0.85em;
	border: none;

	&.rbc-selected {
		background-color: #1a73e8;
	}
}

/* Día fuera del mes actual */
.rbc-off-range-bg {
	background-color: #f8f9fa;
}

/* Día actual */
.rbc-today {
	background-color: rgba(25, 118, 210, 0.05) !important;
}

/* Eliminar los bordes negros anteriores */
.rbc-month-row,
.rbc-month-header,
.rbc-header+.rbc-header,
.rbc-day-bg {
	border-color: #e0e0e0 !important;
}

/* Estilos para los eventos según su estado */
.holiday-event {
	background-color: #8B4513 !important;
	border: none !important;
}

.pending-event {
	background-color: #6f277c !important;
	border: none !important;
}

.approved-event {
	background-color: #2e7d32 !important;
	border: none !important;
}

.rejected-event {
	background-color: #d32f2f !important;
	border: none !important;
}
@primary-color: #222222;@link-color: #226CB7;@success-color: #69DD33;@info-color: #44B7FF;@warning-color: #FFAF3F;@error-color: #FF3030;@font-family: Charlie Display;@layout-header-background: #222222;@border-radius-base: 5px;